import React from 'react'
import Navbar from '../inc/Navbar'
import Block from './Block'
import { Link } from "react-router-dom";


function Blockpage() {
  return (
    <div>
           <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <div style={{ color: "white", padding: "14px;", margin: "6px" }} className="fixed-bottom "> <a href="tel: +91 9434102089" className="colorcall" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>  Call Us <div className="spinner-grow text-light spinner-grow-sm" role="status">
                    <span className="visually-hidden"> Loading...</span>
                </div> </a></div>
                
            <div className="tilesberhamporemurshidabad">
            <div className='album py-1'>
                <div className='container mt-3'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li style={{ color: "green" }} className="breadcrumb-item"><Link to="/" style={{ color: "green" }} >Home</Link></li>
                            <li style={{ color: "green" }} className="breadcrumb-item"><Link to="/contact" style={{ color: "green" }} href="#">Contact</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Block <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg></li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>
            
            <Block/>

    </div>
  )
}

export default Blockpage