import React from "react";
import Navbar from "../inc/Navbar";
import Founder from "../images/founder.jpg";
import { Link } from "react-router-dom";
function About() {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />


      
        <br></br>

        
<div className="tilesberhamporemurshidabad">
        <div className="album py-1">
          <div className="container mt-3">

            <div className="row">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li style={{ color: "Darkblue" }} className="breadcrumb-item"><Link to="/" >Home</Link></li>
                  <li style={{ color: "Darkblue" }} className="breadcrumb-item"><Link to="/paver" >Paver</Link></li>
                  <li style={{ color: "Darkblue" }} className="breadcrumb-item"><Link to="/tiles" >Tiles</Link></li>
                  <li style={{ color: "Darkblue" }} className="breadcrumb-item"><Link to="/brick" >Brick</Link></li>
                  <li style={{ color: "Darkblue" }} className="breadcrumb-item"><Link to="/others" >Others</Link></li>
                  <li style={{ color: "Darkblue" }} className="breadcrumb-item"><Link to="/distributor" >Distributor</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        </div>
        
        <div className="section footer bg-light text-dark">
        <div className="album py-2">

          <div className="container">
            <div className="card mb-4 box-shadow">
              <div className="row">

                <div className="col-md-6 ">

                  <div className="card-body">

                    <img src={Founder} className="card-img-top" alt="Founder" />
                    <div className="d-flex justify-content-between align-items-center">
                    </div>

                  </div>
                </div>


                <div className="col-md-6 ">

                  <div className="card-body">
                    <h3 className="card-text">SINCE 2009</h3>
                    <h5 className="card-text justify">Rupa Eco Brick was founded/established in 2009, and we started full production on 28th February 2011. Since then we have been providing customer service. Then only fly ash bricks were made. After that many other products are being manufactured, including paver blocks, designer tiles and other concrete products. More new products will be presented
                      to you in the future. Your best wishes are very much desired. Hope you will be with us in the future as in the past. </h5>

                    <div className="d-flex justify-content-between align-items-center">


                    </div>



                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

      </div>



    </>
  );
}

export default About;