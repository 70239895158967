import React from 'react'
import Navbar from '../inc/Navbar'
import Benchpage from "./Benchpage";

function Benchp() {
  return (
    <div>
      <Navbar />
      <br/>
      <br />
      <br />
      <br />
      <br />

      <Benchpage />
      <br />
    </div>
  )
}

export default Benchp