
import React from 'react'

import { Link } from "react-router-dom";
import Block120mm from "../images/block120mm.jpg";
import Block100MMnew from "../images/block new.jpg";
import block800mm from "../images/block80mm.jpg";

import block100mmnew from "../images/block100mm new.jpg";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


import {
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
    FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
} from "react-share";
import Sliderblock from '../inc/Sliderblock';

function Block() {
    return (
        <div>
         
        
          
         
            <div className="album py-0">

                <div className="container">

                    <div className="row">


                        <div className="col-md-3 mt-3" >
                            <div className="card mb-4 border-White box shadow">
                                <Link to="/Block120">
                                    <img src={Block120mm} className="card-img-top" alt="block120" /></Link>
                                <div className="card-body">

                                    <h6 className="card-text"> <b> Block 120MM </b></h6>

                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Thickness - 120 MM</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Grade - 50N/MM<sup>2</sup> 450 NO'S/100SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Area - PAVER : 0.222 SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg> Color: Grey</p>
                                    <p style={{ color: "green" }} className="card-text mb-2">  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                    </svg> Apply For Heavy Traffic Zone</p>
                                    <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>  Available in Rupa Eco Brick </p>
                                    <button type="button" className="btn btn btn-dark me-2">
                                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                    </button>

                                    < FacebookShareButton url="https://www.rupaecobrick.com/block" className="me-2 mb-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <FacebookIcon size={37} round={true} />
                                    </FacebookShareButton>

                                    <  WhatsappShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <WhatsappIcon size={37} round={true} />
                                    </ WhatsappShareButton>

                                    < TwitterShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <TwitterIcon size={37} round={true} />
                                    </TwitterShareButton>
                                    <  LinkedinShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  LinkedinIcon size={37} round={true} />
                                    </ LinkedinShareButton>

                                    <  EmailShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  EmailIcon size={37} round={true} />
                                    </ EmailShareButton>

                                    <div className="d-flex justify-content-between align-items-center">

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3 mt-3" >
                            <div className="card mb-4 border-white box shadow">
                                <Link to="#">
                                <Zoom>
                                    <img src={Block100MMnew} className="card-img-top" alt="block100mm" />
                                    </Zoom>
                                    </Link>
                                <div className="card-body">
                                    <h6 className="card-text"> <b> Block 100MM </b></h6>

                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Thickness - 100 MM</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Grade - 55N/MM<sup>2</sup> 450 NO'S/100SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Area - PAVER : 0.222 SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg> Color: Grey</p>
                                    <p style={{ color: "green" }} className="card-text mb-2">  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                    </svg> Apply For Heavy Traffic Zone</p>
                                    <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>  Available in Rupa Eco Brick </p>
                                    <button type="button" className="btn btn btn-dark me-2">
                                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                    </button>

                                    < FacebookShareButton url="https://www.rupaecobrick.com/block" className="me-2 mb-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <FacebookIcon size={37} round={true} />
                                    </FacebookShareButton>

                                    <  WhatsappShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <WhatsappIcon size={37} round={true} />
                                    </ WhatsappShareButton>

                                    < TwitterShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <TwitterIcon size={37} round={true} />
                                    </TwitterShareButton>
                                    <  LinkedinShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  LinkedinIcon size={37} round={true} />
                                    </ LinkedinShareButton>

                                    <  EmailShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  EmailIcon size={37} round={true} />
                                    </ EmailShareButton>
                                    <div className="d-flex justify-content-between align-items-center">
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-3 mt-3" >
                            <div className="card mb-4 border-white box shadow">
                                <Link to="#">
                                <Zoom>
                                    <img src={block100mmnew} className="card-img-top" alt="block100mm" />
                                    </Zoom></Link>

                                <div className="card-body">
                                    <h6 className="card-text"> <b> Block 100MM </b></h6>

                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Thickness - 100 MM</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Grade - 50N/MM<sup>2</sup> 450 NO'S/100SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Area - PAVER : 0.222 SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg> Color: Grey</p>
                                    <p style={{ color: "green" }} className="card-text mb-2">  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                    </svg> Apply For Heavy Traffic Zone</p>
                                    <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>  Available in Rupa Eco Brick </p>
                                    <button type="button" className="btn btn btn-dark me-2">
                                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                    </button>

                                    < FacebookShareButton url="https://www.rupaecobrick.com/block" className="me-2 mb-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <FacebookIcon size={37} round={true} />
                                    </FacebookShareButton>

                                    <  WhatsappShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <WhatsappIcon size={37} round={true} />
                                    </ WhatsappShareButton>

                                    < TwitterShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <TwitterIcon size={37} round={true} />
                                    </TwitterShareButton>
                                    <  LinkedinShareButton url="hhttps://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  LinkedinIcon size={37} round={true} />
                                    </ LinkedinShareButton>

                                    <  EmailShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  EmailIcon size={37} round={true} />
                                    </ EmailShareButton>

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-3 mt-3" >
                            <div className="card mb-4 border-white box shadow">
                                <Link to="#">
                                <Zoom>
                                    <img src={block800mm} className="card-img-top" alt="block80mm" />
                                    </Zoom>
                                    </Link>


                                <div className="card-body">
                                    <h6 className="card-text"> <b> Block 80MM </b></h6>

                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Thickness - 80 MM</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Grade - 50N/MM<sup>2</sup> 450 NO'S/100SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>  Area - PAVER : 0.222 SQFT</p>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" color="green" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg> Color: Grey</p>
                                    <p style={{ color: "green" }} className="card-text mb-2">  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                    </svg> Apply For Heavy Traffic Zone</p>
                                    <p style={{ color: "Gray" }} className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>  Available in Rupa Eco Brick </p>
                                    <button type="button" className="btn btn btn-dark me-2">
                                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                    </button>

                                    < FacebookShareButton url="https://www.rupaecobrick.com/block" className="me-2 mb-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <FacebookIcon size={37} round={true} />
                                    </FacebookShareButton>

                                    <  WhatsappShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <WhatsappIcon size={37} round={true} />
                                    </ WhatsappShareButton>

                                    < TwitterShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <TwitterIcon size={37} round={true} />
                                    </TwitterShareButton>
                                    <  LinkedinShareButton url="hhttps://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  LinkedinIcon size={37} round={true} />
                                    </ LinkedinShareButton>

                                    <  EmailShareButton url="https://www.rupaecobrick.com/block" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  EmailIcon size={37} round={true} />
                                    </ EmailShareButton>

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>

                        </div>




                    </div>
                </div>
            </div>


            <div className="album py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5" >
                          
                                <Sliderblock/>
                                {/* <img src={blockmurshidabad} className="card-img-top" alt="blockmurshidabad"/> */}
                           
                           
                        </div>
                        <div className="col-md-7" >
                            <div className="card" >
                             
                                <div className="card-body">
                                    <h5 style={{color:"green"}} className="card-title mb-3">ADVANTAGES</h5>
                                    <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Pavers require low maintenance as compared to plain concrete or asphalt pavement. </p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> This is very durable and reliable for long lasting </p>
<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Cracking is not developed when they are properly Interlocking with each other. </p>
<p className="card-text mb-2 "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> It is a great material for residential and commercial applications for long-lasting. </p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Block
